<template>
  <div class="bankCard">
    <mt-header :title="$t('bankCard')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div v-if="!bankCard.length" class="listNull">
        <img src="../../../assets/emptyBankCard.png" height="108px" style="margin-top: 80px;">
        <div class="tips">{{ $t('noBankCard') }}</div>
        <div class="bottom">
          <mt-button type="primary" @click="$router.push('/mine/addBankCard')">{{
            $t("addBankCard")
          }}</mt-button>
        </div>
      </div>
      <template v-else>
        <div class="bankList">
          <div class="content">
            <div class="item" v-for="(item, index) in bankCard" :key="index" @click="$router.push('/mine/bankInfo')">
              <img class="logo" src="../../../assets/logo.png">
              <div class="name">{{ item.bankName }}</div>
              <div class="number">{{ item.bankCardNumber }}</div>
            </div>
          </div>
          <div class="bottom">
            <mt-button type="primary" size="large" @click="$router.push('/mine/addBankCard')">{{
              $t("addBankCard")
            }}</mt-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'BankCard',
  components: {
    callPhone
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(["bankCard"]),
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_BANK_CARD']),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getBankCard()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getBankCard() {
      await new Promise(resolve => {
        setTimeout(() => {
          this.SET_BANK_CARD([
            {
              bankName: 'GCash',
              bankCardNumber: '****7778',
            }, {
              bankName: 'GGasp',
              bankCardNumber: '****6666',
            }
          ])
          resolve()
        }, 1000)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.bankCard {
  height: 100vh;
  display: flex;
  flex-direction: column;
  > .content {
    flex: 1;
    .listNull {
      padding: 20px 0px;
      text-align: center;
      .icon {
        font-size: 200px;
        margin: 40px 10px 10px 10px;
      }
      .tips {
        color: #868686;
      }
      .bottom {
        padding: 40px 20px 20px 20px;
        text-align: center;
        .mint-button {
          border-radius: 6px;
          padding: 0 22px;
        }
      }
    }
    .bankList {
      height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        padding: 0px 40px;
        overflow: auto;
        flex: 1;
        .item {
          margin-top: 25px;
          height: 80px;
          border-radius: 10px;
          background: linear-gradient(#5188EF, #6FA0F0);
          padding: 10px 15px;
          display: flex;
          font-size: 15px;
          color: #FFFFFF;
          font-weight: bold;
          line-height: 24px;
          box-shadow: 0px 0px 10px 0px rgba(210,210,210,0.5);
          .logo {
            height: 33px;
            margin-right: 10px;
          }
          .name {
            flex: 1;
          }
        }
      }
      .bottom {
        padding: 20px 40px 40px 40px;
        text-align: center;
        .mint-button {
          border-radius: 6px;
          padding: 0 22px;
        }
      }
    }
  }
}
</style>